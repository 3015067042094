import { URL_REGEX } from '@/shared/constants/regex'
import { z } from 'zod'

export const LooseUrlSchema = z.string().refine(
  url => {
    const result = !!url.match(URL_REGEX)?.length
    return result
  },
  {
    message: 'Invalid URL format',
  }
)
