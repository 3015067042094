import {
  ACTION_TYPE_PARAMETERS_SCHEMA,
  ACTION_TYPES_WITHOUT_INTEGRATION,
  INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA,
  INTEGRATION_NAME_ACTION_TYPES,
  type ActionList,
  type ActionListItem,
  type ActionMap,
  type ActionParametersType,
  type IntegrationActionParametersType,
} from '@/shared/constants/action'
import { type AllContextDataType } from '@/shared/constants/context'
import { type TaskWithContextItems } from '@/shared/constants/task'
import { parseEmailString } from '@/shared/utils/email'
import { isGmailData, isGmailTask } from '@/shared/utils/gmail'
import { correctAndValidateData } from '@/shared/utils/schema'
import { isSlackData, isSlackTask } from '@/shared/utils/slack'
import { getWordCount } from '@/shared/utils/text'
import { getMillisToWrite } from '@/shared/utils/time'
import {
  ActionType,
  IntegrationName,
  type Action,
  type Prisma,
} from '@prisma/client'
import _ from 'lodash'
import pluralize from 'pluralize'
import { z } from 'zod'

// STATUS //

export function isPending(action: Action): boolean {
  return !action.completedDate && !action.cancelledDate && !action.result
}

// LIST //

export function createActionList(
  integrationNames: IntegrationName[]
): ActionList {
  return ACTION_TYPES_WITHOUT_INTEGRATION.map(
    type => ({ type }) as ActionListItem
  ).concat(
    integrationNames
      .flatMap(
        integrationName =>
          INTEGRATION_NAME_ACTION_TYPES[integrationName]?.map(actionType => ({
            integrationName,
            type: actionType,
          }))
      )
      .filter(Boolean) as ActionList
  )
}

export function createActionListMap(
  integrationNames: IntegrationName[],
  options?: {
    disabledActionTypes?: ActionType[]
  }
): ActionMap {
  return createActionList(integrationNames).reduce((map, actionListItem) => {
    if (options?.disabledActionTypes?.includes(actionListItem.type)) {
      return map
    }

    return {
      ...map,
      [`${
        actionListItem.integrationName
          ? actionListItem.integrationName + ':'
          : ''
      }${actionListItem.type}`]: actionListItem,
    }
  }, {} as ActionMap)
}

export function getActionKeys({
  integrationNames,
  options,
}: {
  integrationNames: IntegrationName[]
  options?: {
    disabledActionTypes?: ActionType[]
  }
}) {
  return Object.keys(createActionListMap(integrationNames, options))
}

export function createActionTypeListSchema(
  integrationNames: IntegrationName[],
  options: {
    disabledActionTypes?: ActionType[]
  }
) {
  console.log('Creating action type list schema for %s', integrationNames)
  const actionKeys = getActionKeys({ integrationNames, options })
  if (!actionKeys.length) {
    console.log('No actions available', integrationNames)
    return null
  }

  return z.array(
    z.object({
      action: z.enum(actionKeys as [string, ...string[]]),
      reason: z.string(),
    })
  )
}

export function createActionValidationSchema({
  integrationName,
  actionType,
  task,
  allContextData,
}: {
  integrationName?: IntegrationName | null
  actionType: ActionType
  task: TaskWithContextItems
  allContextData: AllContextDataType
}) {
  if (
    integrationName &&
    (!(integrationName in INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA) ||
      !(
        actionType in
        INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA[
          integrationName as IntegrationName
        ]
      ))
  ) {
    throw new Error(
      `No action parameters schema for integration ${integrationName}`
    )
  }

  const ActionParametersSchema = getActionTypeParameterSchema({
    integrationName,
    actionType,
  })

  if (!ActionParametersSchema) {
    throw new Error(
      `No action parameters schema for integration ${integrationName} and action type ${actionType}`
    )
  }

  const CustomActionSchema = z
    .object({
      type: z.literal(actionType),
      integrationName: z.literal(integrationName),
      parameters: ActionParametersSchema,
    })
    .refine(
      data => {
        if (
          isSlackTask(task) &&
          (actionType === ActionType.SEND_MESSAGE ||
            actionType === ActionType.REPLY_TO_MESSAGE) &&
          isSlackData(allContextData)
        ) {
          const actionParameters = data.parameters as ActionParametersType<
            typeof actionType
          >

          const idMatches = allContextData.conversations.some(
            conversation => conversation.id === actionParameters.conversationId
          )
          if (!idMatches) {
            console.log(
              `Expected conversation id ${
                actionParameters.conversationId
              } to be in ${allContextData.conversations
                .map(conversation => conversation.id)
                .join(', ')}`
            )
            return false
          }
          return true
        }

        return true
      },
      data => {
        if (
          integrationName === IntegrationName.SLACK &&
          (actionType === ActionType.SEND_MESSAGE ||
            actionType === ActionType.REPLY_TO_MESSAGE) &&
          isSlackData(allContextData)
        ) {
          const actionParameters = data.parameters as ActionParametersType<
            typeof actionType
          >
          return {
            message: `Expected conversation id ${
              actionParameters.conversationId
            } to be in ${allContextData.conversations
              .map(conversation => conversation.id)
              .join(', ')}`,
            path: ['parameters'],
          }
        }

        return {}
      }
    )

  return CustomActionSchema
}

export function getButtonText<T extends ActionType>({
  actionType,
  parameters,
  integrationName,
  task,
  allContextData,
}: {
  actionType: T
  parameters: ActionParametersType<T>
  integrationName: IntegrationName | null
  task: TaskWithContextItems
  allContextData?: AllContextDataType
}): string | null {
  switch (actionType) {
    case ActionType.FOLLOW_LINK:
      return parameters
        ? (parameters as ActionParametersType<typeof ActionType.FOLLOW_LINK>)
            ?.title
        : 'Follow link'
    case ActionType.SEND_EMAIL:
      const to = (
        parameters as ActionParametersType<typeof ActionType.SEND_EMAIL>
      ).to
      const toString = Array.isArray(to) ? new Intl.ListFormat().format(to) : to
      return `Send email to ${toString}`
    case ActionType.REPLY_TO_EMAIL:
      if (!isGmailTask(task) || !isGmailData(allContextData)) {
        return `Reply to email`
      }

      const email = allContextData.emails.find(
        email =>
          email.id ===
          (parameters as ActionParametersType<typeof ActionType.REPLY_TO_EMAIL>)
            .emailId
      )
      if (!email) {
        return `Reply to email`
      }

      const { name, email: emailAddress } = parseEmailString(email.from)
      return `Reply to email from ${name ?? emailAddress ?? email.from}`
    case ActionType.FORWARD_EMAIL:
      return `Forward email to ${
        (parameters as ActionParametersType<typeof ActionType.FORWARD_EMAIL>)
          .forwardTo
      }`
    case ActionType.ARCHIVE_EMAIL:
      return `Archive email`
    case ActionType.REPORT_SPAM_EMAIL:
      return `Report spam email`
    case ActionType.ARCHIVE_EMAILS:
      return `Archive ${
        (parameters as ActionParametersType<typeof ActionType.ARCHIVE_EMAILS>)
          .emailIds.length
      } ${pluralize(
        'email',
        (parameters as ActionParametersType<typeof ActionType.ARCHIVE_EMAILS>)
          .emailIds.length
      )}`
    case ActionType.REPORT_SPAM_EMAILS:
      return `Report ${
        (
          parameters as ActionParametersType<
            typeof ActionType.REPORT_SPAM_EMAILS
          >
        ).emailIds.length
      } ${pluralize(
        'email',
        (
          parameters as ActionParametersType<
            typeof ActionType.REPORT_SPAM_EMAILS
          >
        ).emailIds.length
      )} as spam`
    case ActionType.REPLY_TO_MESSAGE:
      return `Reply in thread with ${
        (parameters as ActionParametersType<typeof ActionType.REPLY_TO_MESSAGE>)
          .conversationName
      }`
    case ActionType.SEND_MESSAGE:
      return `Send message to ${
        (parameters as ActionParametersType<typeof ActionType.SEND_MESSAGE>)
          .conversationName
      }`
    case ActionType.CREATE_TASK:
      return `Create ${
        integrationName === IntegrationName.JIRA ? 'issue' : 'task'
      } “${
        (
          parameters as unknown as IntegrationActionParametersType<
            typeof IntegrationName.JIRA,
            typeof ActionType.CREATE_TASK
          >
        ).summary
      }”`
    case ActionType.UPDATE_TASK:
      return `Update ${
        integrationName === IntegrationName.JIRA ? 'issue' : 'task'
      } “${
        (
          parameters as unknown as IntegrationActionParametersType<
            typeof IntegrationName.JIRA,
            typeof ActionType.UPDATE_TASK
          >
        ).originalSummary
      }”`
    case ActionType.COMMENT:
      return `Comment on ${
        integrationName === IntegrationName.JIRA ? 'issue' : 'object'
      } “${
        (parameters as ActionParametersType<typeof ActionType.COMMENT>).title
      }”`
    case ActionType.DELETE_TASK:
      return `Delete task`
    case ActionType.COMPLETE_TASK:
      return `Complete task`
    case ActionType.CREATE_EVENT:
      return `Create event “${
        (parameters as ActionParametersType<typeof ActionType.CREATE_EVENT>)
          .title
      }”`
    case ActionType.SEARCH_MESSAGES:
    case ActionType.UPDATE_EVENT:
    case ActionType.DELETE_EVENT:
    case ActionType.CREATE_NOTE:
    case ActionType.UPDATE_NOTE:
    case ActionType.DELETE_NOTE:
    case ActionType.CREATE_REMINDER:
    case ActionType.UPDATE_REMINDER:
    case ActionType.DELETE_REMINDER:
      console.log(`Unsupported action type: ${actionType}`)
      return `${_.capitalize(actionType)}`
  }

  console.log(`Unknown action type: ${actionType}`)
  return null
}

export function getPossibleActions({
  integrationNames,
  disabledActionTypes,
}: {
  disabledActionTypes?: ActionType[]
  integrationNames: IntegrationName[]
}): ActionList {
  return createActionList(integrationNames).filter(
    actionListItem => !disabledActionTypes?.includes(actionListItem.type)
  )
}

export function getPossibleActionTypes({
  integrationNames,
  disabledActionTypes,
}: {
  integrationNames: IntegrationName[]
  disabledActionTypes?: ActionType[]
}): ActionType[] {
  return _.uniq(
    getPossibleActions({ integrationNames, disabledActionTypes }).map(
      actionListItem => actionListItem.type
    )
  )
}

export function getActionValidationSchema(
  action: Partial<Action>,
  options: {
    integrationNames?: IntegrationName[]
    task: TaskWithContextItems
    allContextData: AllContextDataType
  }
): z.Schema | string {
  if (!action) {
    return 'Invalid action'
  }

  if (!options.integrationNames?.length) {
    return 'No integrations available'
  }

  const actionTypes = getPossibleActionTypes({
    integrationNames: options.integrationNames,
  })

  if (!action?.type || !actionTypes.includes(action.type)) {
    return `Invalid action type ${
      action.type
    } for action. Valid types are ${actionTypes.join(', ')} : ${JSON.stringify(
      action
    )}`
  }

  if (
    !ACTION_TYPES_WITHOUT_INTEGRATION.includes(action.type) &&
    (!action?.integrationName ||
      !options.integrationNames.includes(action.integrationName))
  ) {
    return `Invalid integration name ${
      action.integrationName
    } for action: ${JSON.stringify(action)}`
  }

  return createActionValidationSchema({
    integrationName: action.integrationName,
    actionType: action.type,
    task: options.task,
    allContextData: options.allContextData,
  })
}

export function getValidationErrorMessage(
  action: Partial<Action>,
  options: {
    integrationNames?: IntegrationName[]
    task: TaskWithContextItems
    allContextData: AllContextDataType
  }
): string | null {
  const ActionValidationSchema = getActionValidationSchema(action, options)
  if (typeof ActionValidationSchema === 'string') {
    return ActionValidationSchema
  }

  const validationResult = ActionValidationSchema.safeParse(action)
  if (!validationResult.success) {
    return `Invalid action according to schema: ${JSON.stringify(
      { validationResult, action },
      null,
      2
    )}`
  }

  return null
}

export function getValidAction<T extends Partial<Action>>(
  action: T,
  options: {
    integrationNames?: IntegrationName[]
    task: TaskWithContextItems
    allContextData: AllContextDataType
  }
): T | null {
  const ActionValidationSchema = getActionValidationSchema(action, options)
  if (typeof ActionValidationSchema === 'string') {
    console.warn(
      'Error creating action validation schema',
      ActionValidationSchema
    )
    return null
  }

  const result = correctAndValidateData(ActionValidationSchema, action)
  if (!result.success) {
    console.error('Error correcting action', result.error)
    return null
  }

  return result.data as T
}

export function isValid(
  action: Partial<Action>,
  options: {
    integrationNames?: IntegrationName[]
    task: TaskWithContextItems
    allContextData: AllContextDataType
  }
): action is Action {
  const validationErrorMessage = getValidationErrorMessage(action, options)

  if (validationErrorMessage) {
    console.error(validationErrorMessage)
    return false
  }

  return true
}

export function getIntegrationActionParameters<
  TIntegrationName extends IntegrationName,
  TAction extends ActionType,
>(
  action: (
    | Action
    | Prisma.ActionUncheckedCreateInput
    | {
        parameters: IntegrationActionParametersType<TIntegrationName, TAction>
      }
  ) & { type: TAction }
): IntegrationActionParametersType<TIntegrationName, TAction> {
  return action.parameters as IntegrationActionParametersType<
    TIntegrationName,
    TAction
  >
}

export function getActionParameters<T extends ActionType>(
  action: (
    | Action
    | Prisma.ActionUncheckedCreateInput
    | { type: T; parameters: ActionParametersType<T> }
  ) & { type: T }
): ActionParametersType<T> {
  return action.parameters as ActionParametersType<T>
}

export function getActionWordCount(
  action: Action | Prisma.ActionUncheckedCreateInput
): number {
  return getWordCount(getActionWords(action))
}

export function getActionWords(
  action: Action | Prisma.ActionUncheckedCreateInput
): string {
  const strings: (string | undefined | null)[] = []

  switch (action.type) {
    case ActionType.SEND_MESSAGE:
      const sendMessageOptions = getActionParameters<
        typeof ActionType.SEND_MESSAGE
      >(
        action as Action & {
          type: typeof ActionType.SEND_MESSAGE
        }
      )?.messageOptions
      strings.push(sendMessageOptions[0]?.message)
      break
    case ActionType.REPLY_TO_MESSAGE:
      const replyMessageOptions = getIntegrationActionParameters<
        typeof IntegrationName.SLACK,
        typeof ActionType.REPLY_TO_MESSAGE
      >(
        action as Action & {
          type: typeof ActionType.REPLY_TO_MESSAGE
        }
      )?.messageOptions
      strings.push(replyMessageOptions[0]?.message)
      break
    case ActionType.CREATE_EVENT:
      const parameters = getActionParameters<typeof ActionType.CREATE_EVENT>(
        action as Action & {
          type: typeof ActionType.CREATE_EVENT
        }
      )
      strings.push(
        parameters?.title,
        parameters?.details,
        parameters.location,
        parameters?.start?.toString(),
        parameters?.start?.toString(),
        ...parameters?.attendees
      )
      break
    case ActionType.CREATE_TASK:
      const createTaskOptions = getActionParameters<
        typeof ActionType.CREATE_TASK
      >(
        action as Action & {
          type: typeof ActionType.CREATE_TASK
        }
      )

      strings.push(createTaskOptions?.summary, createTaskOptions?.description)

      break
    case ActionType.SEND_EMAIL:
      const sendEmailOptions = getActionParameters<
        typeof ActionType.SEND_EMAIL
      >(
        action as Action & {
          type: typeof ActionType.SEND_EMAIL
        }
      )

      strings.push(sendEmailOptions?.message, sendEmailOptions?.subject)

      break
    case ActionType.REPLY_TO_EMAIL:
      const replyToEmailOptions = getActionParameters<
        typeof ActionType.REPLY_TO_EMAIL
      >(
        action as Action & {
          type: typeof ActionType.REPLY_TO_EMAIL
        }
      )
      strings.push(replyToEmailOptions?.message)
    case ActionType.REPLY_TO_POST:
      const replyToPostOptions = getIntegrationActionParameters<
        typeof IntegrationName.REDDIT,
        typeof ActionType.REPLY_TO_POST
      >(
        action as Action & {
          type: typeof ActionType.REPLY_TO_POST
        }
      )
      strings.push(replyToPostOptions?.message)
    case ActionType.REPLY_TO_COMMENT:
      const replyToCommentOptions = getIntegrationActionParameters<
        typeof IntegrationName.REDDIT,
        typeof ActionType.REPLY_TO_COMMENT
      >(
        action as Action & {
          type: typeof ActionType.REPLY_TO_COMMENT
        }
      )
      strings.push(replyToCommentOptions?.message)
    case ActionType.FOLLOW_LINK:
      break
    default:
      console.log(`Unsupported action type for word count: ${action.type}`)
      break
  }

  return strings.filter(Boolean).join(' ')
}

export function getActionsWordCount(
  actions?: Action[] | null,
  { useCompletedOnly = true }: { useCompletedOnly?: boolean } = {
    useCompletedOnly: true,
  }
): number {
  return (
    actions
      ?.filter(action => !useCompletedOnly || !!action.completedDate)
      .reduce(
        (wordCount, completedAction) =>
          wordCount + getActionWordCount(completedAction),
        0
      ) ?? 0
  )
}

export function getMillisSaved(action: Action): number {
  return getMillisToWrite(getActionWordCount(action))
}

export function getTrackingData(
  action: Action,
  { prefix = '' } = { prefix: '' }
): Record<string, unknown> {
  return {
    [`${prefix}actionType`]: action.type,
    [`${prefix}actionIntegrationName`]: action.integrationName,
    [`${prefix}actionWordCount`]: action.wordCount,
    [`${prefix}actionTimeSaved`]: getMillisSaved(action),
  }
}

export function getActionTypeParameterSchema<
  TIntegrationName extends IntegrationName,
  TActionType extends ActionType,
>({
  integrationName,
  actionType,
}: {
  integrationName?: TIntegrationName | null
  actionType: TActionType
}): z.Schema | null {
  if (
    ACTION_TYPES_WITHOUT_INTEGRATION.includes(
      actionType as (typeof ACTION_TYPES_WITHOUT_INTEGRATION)[number]
    )
  ) {
    return ACTION_TYPE_PARAMETERS_SCHEMA[actionType]
  }

  if (!integrationName) {
    return null
  }

  const integrationActionTypeParametersSchema =
    INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA[integrationName]
  if (!integrationActionTypeParametersSchema) {
    console.log(
      `No action parameters schema for integration ${integrationName}`
    )
    return null
  }

  if (!(actionType in integrationActionTypeParametersSchema)) {
    console.log(
      `No action parameters schema for integration ${integrationName} and action type ${actionType.toString()}`
    )
    return null
  }
  const ActionParametersSchema =
    integrationActionTypeParametersSchema[
      actionType as unknown as keyof typeof integrationActionTypeParametersSchema
    ]

  if (!ActionParametersSchema) {
    console.log(
      `No action parameters schema for integration ${integrationName} and action type ${actionType.toString()}`
    )
    return null
  }

  return ActionParametersSchema as unknown as z.Schema
}
