import { URL_REGEX } from '@/shared/constants/regex'

export const getWordCount: (text?: string | null) => number = (text = '') => {
  if (!text) {
    return 0
  }

  text = text.replace(/(^\s*)|(\s*$)/gi, '') //exclude  start and end white-space
  text = text.replace(/\n/, ' ') // exclude newline with a start spacing
  text = text.replace(/[ ]{2,}/gi, ' ') //2 or more space to 1
  return text.split(' ').filter(substring => substring != '').length
}

export const getMatchCount: (text: string, regex: RegExp) => number = (
  text,
  regex
) => {
  return (text.match(regex) || []).length
}

// @deprecated use getTruncatedText
export const clampText: (text: string, maxLength: number) => string = (
  text,
  maxLength
) => {
  const filteredText = text.replace(/(\r\n|\n|\r)/gm, ' ')

  if (filteredText.length <= maxLength) {
    return filteredText
  }
  return `${filteredText.slice(0, maxLength)}…`
}

export const removeUrlQueryString: (url: string) => string = url => {
  return url.split('?')[0] || url
}

export const replaceAllUrls = (text: string, replacement = '[URL]'): string => {
  return text.replace(URL_REGEX, replacement)
}

export const condenseWhitespace = (text: string): string => {
  // Trim every line
  text = text
    .split('\n')
    .map(line => line.trim())
    .join('\n')

  // Remove lines that are only whitespace or non-alphanumeric
  text = text.replace(/^[^a-zA-Z0-9]+$/gm, '')

  // Replace multiple newlines with a single newline
  text = text.replace(/(\n\r|\n|\r){2,}/gm, '\n')

  return text
}

export const getTruncatedText = ({
  text,
  searchQuery,
  maxLength,
  isCondensingWhitespace = true,
}: {
  text: string
  searchQuery?: string
  maxLength: number
  isCondensingWhitespace?: boolean
}): string => {
  if (isCondensingWhitespace) {
    text = condenseWhitespace(text)
  }

  const textLength = text.length
  const searchQueryLength = searchQuery?.length ?? 0

  if (textLength <= maxLength) {
    return text
  }

  const maxSubstringLength = Math.floor((maxLength - searchQueryLength) / 2)

  let startIndex =
    searchQuery !== undefined
      ? Math.max(
          text.toLowerCase().indexOf(searchQuery.toLowerCase()) -
            maxSubstringLength,
          0
        )
      : 0

  let endIndex = startIndex + maxLength
  if (endIndex > textLength) {
    startIndex = Math.max(0, startIndex - endIndex + textLength)
    endIndex = textLength
  }

  let truncatedText = text.substring(startIndex, endIndex).trim()
  if (startIndex > 0) {
    truncatedText = `...${truncatedText}`
  }
  if (endIndex < textLength) {
    truncatedText = `${truncatedText}...`
  }
  return truncatedText
}
