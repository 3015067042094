import {
  GMAIL_ACTION_TYPE_PARAMETERS_SCHEMA,
  GMAIL_ACTION_TYPES,
  type GmailReplyToEmailActionResult,
  type GmailSendEmailActionResult,
} from '@/shared/constants/gmail'
import { GoogleCalendarCreateEventSchema } from '@/shared/constants/google-calendar'
import {
  JIRA_ACTION_TYPE_PARAMETERS_SCHEMA,
  JIRA_ACTION_TYPES,
} from '@/shared/constants/jira'
import { REDDIT_ACTION_TYPE_PARAMETERS_SCHEMA } from '@/shared/constants/reddit'
import { type SlackMessage } from '@/shared/constants/slack'
import {
  YOUTUBE_ACTION_TYPE_PARAMETERS_SCHEMA,
  type YoutubeReplyToCommentActionResult,
} from '@/shared/constants/youtube'
import { LooseUrlSchema } from '@/shared/constants/zod'
import { ActionType, IntegrationName } from '@prisma/client'
import { z } from 'zod'

export const SendMessageInputSchema = z.object({
  actionId: z.string(),
  channelId: z.string(),
  message: z.string(),
  integrationName: z.literal(IntegrationName.SLACK),
  // integrationName: z.union([
  //   z.literal(IntegrationName.SLACK),
  //   // z.literal(IntegrationName.MICROSOFT_TEAMS),
  // ]),
})
export const ReplyToMessageInputSchema = z.object({
  actionId: z.string(),
  channelId: z.string(),
  messageId: z.string(),
  message: z.string(),
  integrationName: z.literal(IntegrationName.SLACK),
  // integrationName: z.union([
  //   z.literal(IntegrationName.SLACK),
  //   z.literal(IntegrationName.MICROSOFT_TEAMS),
  // ]),
})

export const SLACK_ACTION_TYPES = [
  ActionType.REPLY_TO_MESSAGE,
  ActionType.SEND_MESSAGE,
] as const
export const NOTION_ACTION_TYPES = []
export const GOOGLE_CALENDAR_ACTION_TYPES = [
  ActionType.CREATE_EVENT,
  // ActionType.DELETE_EVENT,
  // ActionType.UPDATE_EVENT,
]

export const MASS_EMAIL_ACTION_TYPES = [
  ActionType.ARCHIVE_EMAILS,
  ActionType.REPORT_SPAM_EMAILS,
]

export const INTEGRATION_NAME_ACTION_TYPES: Partial<
  Record<IntegrationName, Readonly<ActionType[]>>
> = {
  [IntegrationName.SLACK]: SLACK_ACTION_TYPES,
  [IntegrationName.NOTION]: NOTION_ACTION_TYPES,
  [IntegrationName.GOOGLE_CALENDAR]: GOOGLE_CALENDAR_ACTION_TYPES,
  [IntegrationName.JIRA]: JIRA_ACTION_TYPES,
  [IntegrationName.GMAIL]: GMAIL_ACTION_TYPES,
}

export const MessageOptionSchema = z.object({
  title: z
    .string()
    .describe(
      'A 1-5 word unique title differentiating this message option from the others. For example, "Agree", "Disagree", "Question", etc.'
    ),
  message: z
    .string()
    .describe(
      'The concise message text, written by the user themself, matching the tone of the conversation.'
    ),
})
export const MessageOptionsSchema = z
  .array(MessageOptionSchema)
  .describe(
    'The different options for messages to send. Try to include 3 possible options, sorted by the most likely to be used first. Make sure the titles are distinct and the respective message matches.'
  )
export type MessageOptionsType = z.infer<typeof MessageOptionsSchema>

export const INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA = {
  [IntegrationName.REDDIT]: REDDIT_ACTION_TYPE_PARAMETERS_SCHEMA,
  [IntegrationName.YOUTUBE]: YOUTUBE_ACTION_TYPE_PARAMETERS_SCHEMA,
  [IntegrationName.JIRA]: JIRA_ACTION_TYPE_PARAMETERS_SCHEMA,
  [IntegrationName.SLACK]: {
    [ActionType.REPLY_TO_MESSAGE]: z.object({
      conversationName: z.string().describe('Conversation Name'),
      conversationId: z.string().describe('Conversation ID'),
      originalMessageId: z.string().describe('Original message ID'),
      messageOptions: MessageOptionsSchema,
    }),
    [ActionType.SEND_MESSAGE]: z.object({
      conversationName: z.string().describe('Conversation Name'),
      conversationId: z.string().describe('Conversation ID'),
      messageOptions: MessageOptionsSchema,
    } as const),
    [ActionType.SEARCH_MESSAGES]: z
      .string()
      .describe('Text to search for in messages'),
  },
  [IntegrationName.GMAIL]: GMAIL_ACTION_TYPE_PARAMETERS_SCHEMA,
  [IntegrationName.GOOGLE_CALENDAR]: {
    [ActionType.CREATE_EVENT]: GoogleCalendarCreateEventSchema,
  },
  [IntegrationName.NOTION]: {},
  [IntegrationName.MICROSOFT_TEAMS]: {},
  [IntegrationName.OUTLOOK]: {},
  [IntegrationName.CONFLUENCE]: {},
} as const satisfies Record<
  IntegrationName,
  Partial<Record<ActionType, z.ZodType>>
>

export type IntegrationActionResultType = {
  [IntegrationName.SLACK]: {
    [ActionType.REPLY_TO_MESSAGE]?: SlackMessage
    [ActionType.SEND_MESSAGE]?: SlackMessage
  }
  [IntegrationName.GMAIL]: {
    [ActionType.SEND_EMAIL]?: GmailSendEmailActionResult
    [ActionType.REPLY_TO_EMAIL]?: GmailReplyToEmailActionResult
  }
  [IntegrationName.YOUTUBE]: {
    [ActionType.REPLY_TO_COMMENT]?: YoutubeReplyToCommentActionResult
  }
}

export type IntegrationActionParametersType<
  U extends IntegrationName = IntegrationName,
  T extends ActionType = ActionType,
> = T extends keyof (typeof INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA)[U]
  ? (typeof INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA)[U][T] extends z.ZodType
    ? z.infer<(typeof INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA)[U][T]>
    : never
  : never

// Should only contain actions that don't require integrations
// @deprecated (mostly) Use INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA for integrations
// IMPORTANT: Must be z.object
export const ACTION_TYPE_PARAMETERS_SCHEMA = {
  ...INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA[IntegrationName.REDDIT],
  ...INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA[IntegrationName.JIRA],
  ...INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA[IntegrationName.YOUTUBE],
  ...INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA[IntegrationName.GMAIL],
  [ActionType.FOLLOW_LINK]: z.object({
    title: z.string().describe('Title of link'),
    url: LooseUrlSchema.describe('Link to follow'),
  }),
  [ActionType.REPLY_TO_MESSAGE]: z.object({
    conversationName: z.string().describe('Conversation Name'),
    conversationId: z.string().describe('Conversation ID'),
    originalMessageId: z.string().describe('Original message ID'),
    messageOptions: MessageOptionsSchema,
  }),
  [ActionType.SEND_MESSAGE]: z.object({
    conversationName: z.string().describe('Conversation Name'),
    conversationId: z.string().describe('Conversation ID'),
    messageOptions: MessageOptionsSchema,
  } as const),
  [ActionType.COMMENT]: z.object({
    id: z.string().describe('ID of object being commented on'),
    title: z.string().describe('Title of object being commented on'),
    comment: z.string().describe('Comment'),
  }),
  [ActionType.CREATE_EVENT]: GoogleCalendarCreateEventSchema,
  // TODO
  [ActionType.SEARCH_MESSAGES]: z.object({ placeholder: z.string() }),
  [ActionType.DELETE_TASK]: z.object({ placeholder: z.string() }),
  [ActionType.COMPLETE_TASK]: z.object({ placeholder: z.string() }),
  [ActionType.UPDATE_EVENT]: z.object({ placeholder: z.string() }),
  [ActionType.DELETE_EVENT]: z.object({ placeholder: z.string() }),
  [ActionType.CREATE_NOTE]: z.object({ placeholder: z.string() }),
  [ActionType.UPDATE_NOTE]: z.object({ placeholder: z.string() }),
  [ActionType.DELETE_NOTE]: z.object({ placeholder: z.string() }),
  [ActionType.CREATE_REMINDER]: z.object({ placeholder: z.string() }),
  [ActionType.UPDATE_REMINDER]: z.object({ placeholder: z.string() }),
  [ActionType.DELETE_REMINDER]: z.object({ placeholder: z.string() }),
} as const satisfies Record<ActionType, z.SomeZodObject>
// @deprecated in favor of ACTION_TYPE_PARAMETERS_SCHEMA
export type ActionParametersType<T extends ActionType = ActionType> = z.infer<
  (typeof ACTION_TYPE_PARAMETERS_SCHEMA)[T]
>

export type ActionListResponseType = { action: string; reason: string }[]
export type ActionListItem = {
  integrationName?: IntegrationName
  type: ActionType
  reason?: string
}
export type ActionList = ActionListItem[]
export type ActionMap = Record<string, ActionListItem>

export const BaseActionSchemaProperties = {
  title: z.string().describe('The title of the action'),
  reason: z.string().describe('The reason the action was created'),
}
export const ActionSchema = z.object({
  type: z.nativeEnum(ActionType).describe('The type of action'),
  title: z.string().describe('The title of the action'),
  reason: z.string().describe('The reason the action was created'),
  integrationName: z
    .nativeEnum(IntegrationName)
    .describe('The integration name'),
  parameters: z
    .any()
    .describe('The parameters for the type of action specified'),
})
export type ActionSchemaType = z.infer<typeof ActionSchema>

export const ActionsSchema = z.object({
  actions: z.array(ActionSchema),
})
export type ActionsSchemaType = z.infer<typeof ActionsSchema>

export const ACTION_TYPES_WITHOUT_INTEGRATION: ActionType[] = [
  ActionType.FOLLOW_LINK,
]

export const ACTION_TYPES_WITH_EMAIL_ADDRESS_REQUIRED: ActionType[] = [
  ActionType.CREATE_EVENT,
  ActionType.UPDATE_EVENT,
  ActionType.SEND_EMAIL,
  ActionType.REPLY_TO_EMAIL,
]

export const ACTION_TYPES_WITH_MESSAGE_OPTIONS: ActionType[] = [
  ActionType.SEND_MESSAGE,
  ActionType.REPLY_TO_MESSAGE,
]

export const ACTION_TYPES_WITH_EMAIL_BODY: ActionType[] = [
  ActionType.SEND_EMAIL,
  ActionType.REPLY_TO_EMAIL,
] as const

export const ACTION_TYPES_WITH_EVENT_ID: ActionType[] = [
  ActionType.UPDATE_EVENT,
  ActionType.DELETE_EVENT,
]

export type EmailListType = { name: string; email: string }[]
export const NO_REPLY_PATTERNS = [
  // /^admin/i,
  /^alert/i,
  /^announce/i,
  /^automated/i,
  /^automated/i,
  /^autoresponder/i,
  /^deals/i,
  /do_not_reply/i,
  /do-not-reply/i,
  /donotreply/i,
  // /^info/i,
  /^mail$/i,
  /mailer-daemon/i,
  // /^marketing/i,
  /^newsletter/i,
  /no-reply/i,
  /no_reply/i,
  /noreply/i,
  /^noresponse/i,
  /notification/i,
  /notify/i,
  /^offers/i,
  /^order/i,
  /^promotions/i,
  /^purchase/i,
  /^system/i,
  /^transaction/i,
  /^update/i,
  // /webmaster/i,
]

export type ActionContextMetadataType = Record<string, unknown> & {
  examples?: string[]
}
