import dayjs from '@/shared/singletons/dayjs'
import _ from 'lodash'
import { z } from 'zod'

const fixZodIssue = ({
  issue,
  data,
}: {
  issue: z.ZodIssue
  data: object
}): object => {
  const existingValue = _.get(data, issue.path.join('.'))
  console.debug('Fixing Zod issue', { issue, data, existingValue })

  if (
    (issue as { validation: string }).validation === 'datetime' ||
    (issue.code === 'invalid_type' && issue.expected === 'date')
  ) {
    const existingDate = existingValue
    const sanitizedDate = sanitizeDate(existingDate)
    return _.set(data, issue.path.join('.'), sanitizedDate)
  } else if (issue.code === 'invalid_enum_value') {
    const existingEnum = issue.received
    const matchingEnum = issue.options.find(
      option =>
        option.toString().toLowerCase() ===
        existingEnum.toString().toLowerCase()
    )
    if (matchingEnum == null) {
      console.debug('Could not find matching enum', { existingEnum, issue })
      return data
    }
    return _.set(data, issue.path.join('.'), matchingEnum)
  } else if (issue.code === 'invalid_literal') {
    const existingLiteral = existingValue
    const expectedLiteral = issue.expected
    if (typeof expectedLiteral !== 'string') {
      console.log('Invalid expected literal', { existingLiteral, issue })
      return data
    }
    const isMatching =
      existingLiteral.toLowerCase() === expectedLiteral.toLowerCase()
    if (!isMatching) {
      console.debug('Literal is not matching', { existingLiteral, issue })
      return data
    }
    return _.set(data, issue.path.join('.'), expectedLiteral)
  } else if (issue.code === 'too_big') {
    return _.set(data, issue.path.join('.'), issue.maximum)
  } else if (issue.code === 'too_small') {
    return _.set(data, issue.path.join('.'), issue.minimum)
  } else if (issue.code === 'invalid_union') {
    issue.unionErrors.forEach(unionError => {
      const issue = unionError.issues[0]
      if (!issue) {
        return
      }

      console.debug('Attempting to fix invalid union error %O', {
        issue,
        data,
        unionError,
      })
      data = fixZodIssue({ issue, data })
    })

    return data
  }

  // Delete the the invalid property
  let invalidPropertyParentPath = issue.path.slice(0, -1).join('.')
  let invalidPropertyParent = _.get(data, invalidPropertyParentPath) ?? data
  let invalidPropertyPath = issue.path.at(-1)
  if (invalidPropertyPath == null || invalidPropertyParent == null) {
    console.log('No parent to delete', {
      invalidPropertyParentPath,
      invalidPropertyParent,
      invalidPropertyPath,
    })
    return data
  }

  if (!(invalidPropertyPath in invalidPropertyParent)) {
    // The property doesn't exist, delete the parent
    invalidPropertyParentPath = issue.path.slice(0, -2).join('.')
    invalidPropertyParent = _.get(data, invalidPropertyParentPath)
    invalidPropertyPath = issue.path.at(-2)
    if (invalidPropertyPath == null || invalidPropertyParent == null) {
      console.log('No grandparent or parent to delete', {
        invalidPropertyParentPath,
        invalidPropertyParent,
        invalidPropertyPath,
      })
      return data
    }
  }

  console.log('Deleting invalid property', {
    invalidPropertyParentPath,
    invalidPropertyParent,
    invalidPropertyPath,
  })
  if (Array.isArray(invalidPropertyParent)) {
    // For arrays, remove the item at the index to reduce the array's size
    if (typeof invalidPropertyPath === 'number') {
      invalidPropertyParent.splice(invalidPropertyPath, 1)
    }
  } else if (typeof invalidPropertyParent === 'object') {
    // For objects, use delete to remove the property
    delete invalidPropertyParent[invalidPropertyPath]
  }

  console.log('Deleting invalid property', {
    invalidPropertyParentPath,
    invalidPropertyParent,
    invalidPropertyPath,
    data,
  })
  return _.set(data, invalidPropertyParentPath, invalidPropertyParent)
}

export const correctAndValidateData = <T extends object>(
  schema: z.Schema<T>,
  data: T | object,
  lastError?: string,
  lastData?: T | object
):
  | { success: true; data: T }
  | { success: false; data: object; error: string } => {
  const validationResult = schema.safeParse(data)

  if (!validationResult.success) {
    const newError = validationResult.error.toString()
    if (newError === lastError && _.isEqual(data, lastData)) {
      console.error(
        'Validation failed after attempt to correct:',
        validationResult.error.issues
      )
      return { success: false, data, error: validationResult.error.message }
    }

    console.log(
      'Validation failed, attempting to correct: %O\n%O',
      validationResult.error.issues,
      {
        data,
        lastError,
        lastData,
      }
    )
    let newData = _.cloneDeep(data)

    // IMPORTANT: Only correct 1 issue at a time to prevent deleting invalid array indices
    validationResult.error.issues.slice(0, 1).forEach(issue => {
      newData = fixZodIssue({ issue, data: newData })
    })

    console.log(
      'Validating corrected data:\nOld: %O\nNew: %O\nError: %O',
      data,
      newData,
      newError
    )
    return correctAndValidateData(schema, newData, newError, data)
  }

  if (lastError) {
    console.log('Fixed validation error: %s, %O', lastError, data)
  }
  return { success: true, data: validationResult.data as T }
}

export function sanitizeDate(date: unknown): Date | null {
  if (date instanceof Date) {
    return date
  }

  if (typeof date === 'string' && _.isDate(new Date(date))) {
    // Check if date is valid
    const dayjsDate = dayjs(
      typeof date === 'string' ? date.replaceAll(' ', '') : date
    )
    if (!dayjsDate.isValid()) {
      return null
    }

    return dayjsDate.toDate()
  }

  return null
}

export function getMaxLength(schema: z.ZodTypeAny): number | undefined {
  const hasMaxLength = z.object({ maxLength: z.number() }).safeParse(schema)
  if (hasMaxLength.success) {
    return hasMaxLength.data.maxLength
  }

  if ('unwrap' in schema && typeof schema.unwrap === 'function') {
    return getMaxLength(schema.unwrap())
  }

  if ('options' in schema && Array.isArray(schema.options)) {
    for (const option of schema.options) {
      const maxLength = getMaxLength(option)
      if (maxLength != null) {
        return maxLength
      }
    }
  }
}
